import React, { useState } from 'react';
import './DownloadGeneratedFilesModal.css';
import Download from "@mui/icons-material/Download";
import { generatedFilesConstant } from './generatedFilesConstant';
import Box from '@mui/material/Box';
import { Skeleton } from '@mui/material';
import { getGeneratedFile } from '../../../actions/fileManager';
import { useParams } from 'react-router-dom';
import iziToast from 'izitoast';
import { internalServerError } from '../../../utils/consts/pathRoutes';

const DownloadGeneratedFilesModal = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [currentType, setCurrentType] = useState('');
  const userId = useParams().id;

  const handleSelectFile = (type) => {
    setCurrentType(type)
    setIsLoading(true)

    getGeneratedFile(userId, type)
      .then((response) => {
        let url = window.URL.createObjectURL(response.data);
        let a = document.createElement('a');
        a.href = url;
        a.setAttribute('download', `${type}.docx`);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
      })
      .catch((e) => {
        iziToast.error({
          message: internalServerError(e),
          position: "topRight",
          color: "#FFF2ED"
        });
      })
      .finally(() => {
        setCurrentType('')
        setIsLoading(true)
      })
  }

  return (
    <div className="generatedFilesContainer">
      <h1 className="title">
        Автоматическое генерирование документов
      </h1>
      <div className="filesBlock">
        {generatedFilesConstant.map(({sectionKey, title, list}) => {
          return (
            <div key={`generated-files-section-${sectionKey}`} className="sectionBlock">
              <h2 className="sectionTitle">
                {title}
              </h2>
              {list.map(({key, type, title, description }) => {
                if (isLoading && currentType.type === type)
                  return <Skeleton sx={{height: '30px'}} />
                return (
                  <Box  key={`generated-file-${key}`} sx={{display: 'flex', gap: "6px", alignItems: 'center'}}>
                    <p className="listDot">•</p>
                    <div className="textWithIcon">
                      <div className="textWithDescription">
                        <p className="fileSection">
                          {title}
                        </p>
                        {description && <p className="description">
                          {description}
                        </p>}
                      </div>
                      <Download className="downloadIcon" onClick={() => handleSelectFile(type)} />
                    </div>
                  </Box>
                )
              })}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default DownloadGeneratedFilesModal;
