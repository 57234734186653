export const generatedFilesConstant = [
  {
    sectionKey: 1,
    title: 'Поселение',
    list: [
      {
        key: 1,
        type: "admissionApplication",
        title: "Анкета проживающего в общежитии",
        description: "",
      },
      {
        key: 2,
        type: "residentQuestionnaire",
        title: "Заявление о приеме на Подфак",
        description: "c согласием на обработку персональных данных",
      }
    ]
  },
  {
    sectionKey: 2,
    title: 'Справки',
    list: [
      {
        key: 1,
        type: "admissionLetter",
        title: "Об обучении слушателя на программе",
        description: "",
      },
    ]
  }
];
