import React from 'react';
import '../../Modals/CreateTaskModal/Modal.css';
import StudentDisk from './file/StudentDisk/StudentDisk';
import CreateFormDisk from './file/CreateFormDisk/CreateFormDisk';
import ModalCustom from '../../ModalCustom/ModalCustom';

const ModalFile = ({ active, setActive, studentId = null }) => {
  return (
    <ModalCustom active={active} setActive={setActive}>
      {studentId
        ?
        <StudentDisk studentId={studentId}/>
        :
        <CreateFormDisk />
      }
    </ModalCustom>
  );
};
export default ModalFile;
