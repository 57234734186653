import React, { useState } from 'react';
import '../../../../Modals/CreateTaskModal/Modal.css';
import './dir.css';
import { useDispatch, useSelector } from "react-redux";
import { createDir } from "../../../../../store/api-actions";
import { getCurrentDir } from "../../../../../store/slices/ManagerData/selectors";
import ModalCustom from '../../../../ModalCustom/ModalCustom';

const ModalDirectory = ({ active, setActive, studentId }) => {
  const [name, setName] = useState('');
  const currentDir = useSelector(getCurrentDir);

  const dispatch = useDispatch();


  const createDirHandler = () => {
    dispatch(createDir({ studentId: studentId, parentId: currentDir, name: name }));
    setActive(false);
  };

  return (
    <ModalCustom active={active} setActive={setActive}>
      <div className="dir__content">
        <h2>Добавление папки</h2>
        <input className="dir__input" type="text" placeholder="Имя папки" value={name}
               onChange={(event) => setName(event.target.value)} />
        <button className="dir__button" onClick={createDirHandler}>Добавить</button>
      </div>
    </ModalCustom>
  );
};

export default ModalDirectory;
