export const pricesAmount = [
    {
        hour: '1008 (1.5 года 25-26 г.)',
        cost: 175000
    },
    {
        hour: '1008 (1.5 года 24-25 г.)',
        cost: 159000
    },
    {
        hour: '1008 (1 год 24-25 г.)',
        cost: 150000
    },
    {
        hour: '1008 (1.5 года 23-24 г.)',
        cost: 136000
    },
    {
        hour: '1008 (1 год 23-24 г.)',
        cost: 140000
    },
    {
        hour: '868 (24-25 уч.г.)',
        cost: 140000
    },
    {
        hour: '868 (23-24 уч.г.)',
        cost: 128800
    },
    {
        hour: '728 (24-25 уч.г.)',
        cost: 130000
    },
    {
        hour: '728 (23-24 уч.г.)',
        cost: 119000
    },
    {
        hour: '588 (24-25 уч.г.)',
        cost: 119000
    },
    {
        hour: '588 (23-24 уч.г.)',
        cost: 108500
    },
    {
        hour: '504 (24-25 уч.г.)',
        cost: 101000
    },
    {
        hour: '504 (23-24 уч.г.)',
        cost: 92000
    },
    {
        hour: '352 (24-25 уч.г.)',
        cost: 60000
    },
    {
        hour: '352 (23-24 уч.г.)',
        cost: 60000
    },
    {
        hour: '288 (24-25 уч.г.)',
        cost: 60000
    },
    {
        hour: '288 (23-24 уч.г.)',
        cost: 55000
    },
    {
        hour: '144 (24-25 уч.г.)',
        cost: 32000
    },
    {
        hour: '144 (23-24 уч.г.)',
        cost: 29000
    },
    {
        hour: '108 (24-25 уч.г.)',
        cost: 25000
    },
    {
        hour: '108 (23-24 уч.г.)',
        cost: 23000
    },
    {
        hour: '72 (24-25 уч.г.)',
        cost: 140000
    },
    {
        hour: '72 (23-24 уч.г.)',
        cost: 140000
    },
]
