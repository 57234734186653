import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { fetchFilesAction, uploadFile } from "../../../../../store/api-actions";
import { getCurrentDir, getDirStack, getIsLoading } from "../../../../../store/slices/ManagerData/selectors";
import ModalDirectory from "../ModalDirectory";
import { popDirStack, setCurrentDir } from "../../../../../store/slices/ManagerData/manager-data";
import Spinner from "../../../Spinner";
import { getDiskSize } from "../../../../../actions/fileManager";
import SimCardDownloadOutlinedIcon from '@mui/icons-material/SimCardDownloadOutlined';
import StudentFileList from '../FileList/StudentFileList/StudentFileList';

import './Disk.css';
import Tooltip from '@mui/material/Tooltip';
import ModalCustom from '../../../../ModalCustom/ModalCustom';
import DownloadGeneratedFilesModal from '../../../../Modals/DownloadGeneratedFilesModal/DownloadGeneratedFilesModal';


const StudentDisk = ({ studentId }) => {
  const dispatch = useDispatch();
  const currentDir = useSelector(getCurrentDir);
  const dirStack = useSelector(getDirStack);
  const isLoading = useSelector(getIsLoading);
  const [isCreateDir, setIsCreateDir] = useState(false);
  const [isDownloadGeneratedFiles, setIsDownloadGeneratedFiles] = useState(false);

  const [diskData, setDiskData] = useState({
    freeSpace: 0,
    diskSize: 0
  });

  useEffect(() => {
    dispatch(fetchFilesAction({ studentId: studentId, parentId: currentDir }));

    getDiskSize()
      .then(data => {
        setDiskData(data);
      });
  }, [studentId, currentDir]);

  const openCreateDir = () => {
    setIsCreateDir(true);
  };

  const handleOpenDownloadGeneratedFiles = () => {
    setIsDownloadGeneratedFiles(prevState => !prevState);
  };

  const backClickHandler = () => {
    const b = dirStack[dirStack.length - 1];
    dispatch(popDirStack());
    dispatch(setCurrentDir(b.id));
  };

  const uploadFileHandler = (event) => {
    const files = [...event.target.files];
    dispatch(uploadFile({ files: files, studentId: studentId, parentId: currentDir }));
  };

  return (
    <>
      {
        isLoading
          ?
          <Spinner />
          :
          <div className="disk">
            <div className="disk_btns">
              <button className="disk_style_btns" onClick={backClickHandler}>Назад</button>
              <button className="disk_style_btns" onClick={openCreateDir}>Добавить папку</button>
              <div className="disk_style_btns">
                <label htmlFor="disk_upload_id">Выберите файл</label>
                <input type="file" id="disk_upload_id" style={{ display: "none" }}
                       onChange={uploadFileHandler} multiple={true} />
              </div>
              <div className="disk_data_label">
                {`Свободно ${diskData.freeSpace} ГБ из ${diskData.diskSize} ГБ`}
              </div>

              <Tooltip title="Автоматическое генерирование файлов" placement="top">
                <SimCardDownloadOutlinedIcon className="downloadGeneratedFiles"
                                             onClick={handleOpenDownloadGeneratedFiles} />
              </Tooltip>
            </div>
            {
              dirStack.map((item) => {
                return <> <span className="disk_style_bread">{item.name}</span> /</>;
              })
            }
            <StudentFileList />
            {
              isCreateDir && <ModalDirectory active={isCreateDir} setActive={setIsCreateDir} studentId={studentId} />
            }
            {
              isDownloadGeneratedFiles &&
              <ModalCustom active={isDownloadGeneratedFiles} setActive={setIsDownloadGeneratedFiles}>
                <DownloadGeneratedFilesModal />
              </ModalCustom>
            }
          </div>
      }
    </>
  );
};

export default StudentDisk;
