import React from 'react';

import Box from '@mui/material/Box';
import { Modal } from '@mui/material';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'fit-content',
  bgcolor: 'background.paper',
  boxShadow: 2,
  borderRadius: '12px',
  p: 4,
};


const ModalCustom = ({ active, setActive, otherStyles, children }) => {
  const handleClose = () => setActive(false);

  return (
    <Modal
      open={active}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={{...style, ...otherStyles}}>
        {children}
      </Box>
    </Modal>
  );
};

export default ModalCustom;
